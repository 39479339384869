const Contact = () => {
    return(
        <>
        <div className="contact">
            <h3 className="contactHead">Contact Info</h3>
            <p>
                Phone Number: 307-426-4008<br></br>
                Email: foreverwestfest@yahoo.com<br></br>
                Address to the Tribe: 110 E Lincolnway suite C, Cheyenne, WY 82001<br></br>
                Address of Event: 1 Depot Square Capitol, W 15th st, Cheyenne, WY 82007<br></br>
                Dates: July 13th-16th
            </p>
        </div>
        </>
    )
}

export default Contact