import Andrew from '../assets/Andrew.jpg';
import Krisha from '../assets/Krisha.jpg';
import ShawnOhare from '../assets/ShawnOhare.jpg'

const PiercerContent = [
    {Title:'Andrew Butler', Text:  'Cheyenne, Wyoming: The T.R.I.B.E. Zoo Tattoo', Image: Andrew, Link: 'https://www.instagram.com/aandrew836/' },
    {Title:'Krisha JTot', Text: 'Englewood Colorado: Phantom 8', Image: Krisha, Link:'https://phantom8.com/krisha-jtot-2/'},
    {Title:'Shawn O Hare', Text: 'Body Mod by Shawn', Image: ShawnOhare, Link:'https://www.bodymodsbyshawn.com/'}


       ]
    
    export default PiercerContent